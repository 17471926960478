import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const headerSlider = document.querySelector('.slider-header--carousel');

  if (headerSlider) {
    new Swiper(headerSlider, {
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      },
      pagination: {
        el: headerSlider.querySelector('.swiper-pagination'),
        clickable: true,
      },
    });
  }
});
